//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-412:_3468,_3904,_5300,_4547,_5672,_5032,_5656,_455;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-412')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-412', "_3468,_3904,_5300,_4547,_5672,_5032,_5656,_455");
        }
      }catch (ex) {
        console.error(ex);
      }