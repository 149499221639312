const searchPageUrl = '/search.html';

globalThis.Convermax.quickview = (item) => (e) => {
  e.preventDefault();
  window.quickview(`/product.asp?lt_c=1&itemid=${item.CatalogID}&qv=1`);
};

export default {
  platform: 'shift4shop',
  searchPageUrl,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  facets: {
    rangedFacet: { fields: ['Price'], name: 'priceFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.category-page .category-products',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchBox',
      location: '#searchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxSticky',
      type: 'SearchBox',
      location: '#msearchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.site-header .head-mobile-nav a[data-target="#searchModal"]',
        wrapper: 'a',
        class: 'pull-right',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: window.location.pathname === searchPageUrl && ['Category'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
