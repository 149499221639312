export default {
  PRODUCT_REPEATER_CLASS: 'product-items product-items-3',
  BUTTON_PRIMARY_CLASS: 'button btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'button btn btn-primary',
  LOAD_MORE_BUTTON_CLASS: 'btn btn-default',
  FACET_BUTTON_CLASS: '',

  SEARCH_BOX_FORM_CLASS: 'search-form',
  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',
};
